export default {
	adds:{
		loan_state_4: "Falhar ",
		loan_state_3: "Reembolsado",
		loan_state_2: "Desembolsado",
		loan_state_1: "A aguardar aprovação",
		repay_state_2: "Reembolsado",
		repay_state_1: "Não reembolso",
		repay_create: "Período do empréstimo",
		repay_state: "Estado das coisas",
		repay_times: "Fase",
		repay_time: "Período de reembolso",
		repay_money: "Reembolso total",
		repay_total: "Montante do empréstimo",
		repay_pay: "Registos de reembolso",
		repay: "Registos de empréstimos",
		loan_pic4: "Identificação com fotografia",
		loan_pic3: "Extrato bancário",
		loan_pic2: "Prova de rendimentos",
		loan_pic1: "Certificado de propriedade",
		loan_pic: "Empréstimos de crédito (certifique-se de que as imagens são claramente visíveis)",
		loan_pics: "Formulário de identificação",
		loan_pics_view: "Clique para ver",
		loan_repay_method_1: "Reembolso de capital e juros de uma só vez" ,
		loan_repay: "Método de reembolso",
		loan_apr: "Taxa de juro diária",
		loan_time: "Ciclo do empréstimo",
		loan_type_1: "Dia",
		loan_type_2: "Lua",
		loan_money: "Montante do empréstimo",
		loan: "Empréstimo estudantil",
		one: "Configurações da conta",
		two: "Configurações de coleta",
		three: "Configurações de identidade",
		four: "Feedback do usuário",
		five: "Fazer logout",
		verifySuccess: "Certificado",
		verifyFailed: "Não certificado",
		verifyPass: "Verificar a Senha",
		notice: "AVISO",
		logout_tip: "Tens a certeza que vais sair?",
		day: "Dia",
	},
	types: [
		{ v: 5, name: 'Conta financeira' },
		{ v: 4, name: 'Conta Extreme Contracts' },
		{ v: 3, name: 'Conta em moeda fiduciária' },
		{ v: 2, name: 'Conta de contrato' },
		{ v: 1, name: 'Conta de moedas' },
	],
	billTypes: [
		{v: 101, name: 'Recarga (dinheiro em um cartão)'},
		{v: 102, name: 'Depósito (por exemplo, em uma conta bancária)'},
		{v: 103, name: 'Congelamento (empréstimo, salário, preço etc.)'},
		{v: 104, name: 'Descongelar'},
		{v: 105, name: 'Depósito (por exemplo, em uma conta bancária)'},
		{v: 106, name: 'Depósito (por exemplo, em uma conta bancária)'},
		{v: 201, name: 'Congelamento de saques'},
		{v: 202, name: 'Retirar fundos'},
		{v: 203, name: 'Retirada bem-sucedida'},
		{v: 204, name: 'Não retirar fundos'},
		{v: 205, name: 'Taxa de retirada'},
		{v: 206, name: 'Transferência'},
		{v: 207, name: 'Mudar para'},
		{v: 208, name: 'Transferência de moedas'},
		{v: 209, name: 'Transferência de moedas'},
		{v: 301, name: 'Taxa de manuseio de moedas'},
		{v: 302, name: 'Receita contratual'},
		{v: 303, name: 'Perda de contrato'},
		{v: 311, name: 'Compra de opções'},
		{v: 312, name: 'Ganho com opção'},
		{v: 313, name: 'Devoluções de opções'},
		{v: 314, name: 'Taxa de manuseio de opções'},
		{v: 315, name: 'Congelamento de compra de moedas'},
		{v: 316, name: 'Dedução na compra de moedas'},
		{v: 317, name: 'Devoluções de compras de moedas'},
		{v: 318, name: 'Entrada de moedas na conta'},
		{v: 319, name: 'Entrada de moedas na conta'},
		{v: 320, name: 'Congelamento da venda de moedas'},
		{v: 321, name: 'Dedução na venda de moedas'},
		{v: 322, name: 'Devoluções de vendas de moedas'},
		{v: 323, name: 'Venda de moedas para a conta'},
		{v: 324, name: 'Venda de moedas para a conta'},
		{v: 325, name: 'Taxa de manuseio de moedas'},
		{v: 401, name: 'Mineradores se unem'},
		{v: 402, name: 'Retorno do minerador'},
		{v: 403, name: 'Receita de máquinas de mineração'},
		{v: 404, name: 'Saída da máquina de mineração'},
		{v: 405, name: 'Taxa de saída da mineração'},
		{v: 501, name: 'Compra de caixa cega'},
		{v: 502, name: 'Receita de caixa-cega'},
	],
	common: {
		confirm: "Reconhecer",
		cancel: "Cancelamentos",
		submit: "Apresentar (um relatório etc.)",
		reset: "Reprovisão",
		next: "A próxima etapa",
		review: "Em análise",
		copy_success: "Sucesso na cópia！",
		copy_error: "Falha na reprodução！",
		more: "Mais",
		success: "Sucessos",
		fail: "Falhar (por exemplo, experimentos)",
		place_input: "Digite o conteúdo",
		form_empty: "Nenhum registro",
		download: "Download",
		history_record: "Registro histórico",
		closing_position: "Fechar uma posição",
		tip: "Chamar a atenção para algo",
		forget: "Esqueceu a senha?",
		all: "Completo",
	},
	message: {
		cancel_order: "Se a comissão deve ser revogada?",
		stop_out: "Se a posição é ou não forçada a ser fechada?",
	},
	header: {
		nav1: "Negociação de moedas Fiat",
		nav2: "Comércio de moedas",
		nav3: "Negociação de contratos",
		nav4: "Segundo contrato (em esportes)",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "Meus ativos",
		setting_account: "Configurações da conta",
		setting_collection: "Configurações de cobrança de pagamento",
		setting_authentication: "Configurações de identidade",
		setting_feedback: "Feedback do usuário",
		logout: "Fazer logout",
	},
	footer: {
		tit1: "Sobre nós",
		tit2: "Suporte ao usuário",
		tit3: "Central de Ajuda",
		tit4: "Entre em contato conosco",
		tit5: "Atendimento ao cliente on-line",
		p1: "Introdução à plataforma",
		p2: "Anúncio do projeto",
		p3: "Política de privacidade",
		p4: "Como se registrar por e-mail",
		p5: "Como autenticar seu nome",
		p6: "Como definir uma senha de negociação",
		p7: "O que é reabastecimento de moedas, como reabastecer moedas",
		p8: "O que é retirada de moedas, como retirar moedas",
		p9: "Guia para iniciantes no comércio de moedas",
	},
	assets: {
		asset_verview: "Visão geral dos ativos",
		asset_verview_conversion: "Visão geral do ativo convertida",
		account_assets: "Ativos da conta",
		financial_records: "Registros financeiros",
		tab_trade: "Conta de moedas",
		tab_legal: "Conta em moeda fiduciária",
		tab_lever: "Conta de contrato",
		tab_second: "Conta Extreme Contracts",
		tab_financial: "Conta financeira",
		tab_transfer: "Transferência",
		recharge: "Carregamento de moedas",
		withdraw: "Retirar dinheiro",
		currency_transfer: "Transferência de moeda",
		asset_transfer: "Transferência de ativos",
		transfer_record: "Transferência de registros",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "Registros DeFi",
		reference_annualization: "Referência Anualizada",
		state: "Estado das coisas",
		term: "Prazos",
		day: "Céu",
		buy: "Compra",
		min_amount: "Valor mínimo",
		daily: "Produção diária",
		waiting_time: "Tempo de espera",
		time: "Horários",
		everyday: "Cotidiano",
		escrow_funds: "Fundos mantidos em fideicomisso",
		returnType: "Retorno no vencimento",
		early_withdrawal: "Saque antecipado de dinheiro",
		estimated_income: "Renda projetada",
		available_assets: "Ativo disponível"
	},
	home: {
		download: "Download do TCOIN",
		ios_download: "Download do IOS",
		android_download: "Download para Android",
		banner_p1: "Ative rapidamente seu portfólio -->",
		banner_p2: "Inicie rapidamente seu",
		banner_p3: "Portfólio criptografado",
		banner_p4: "A TCOIN é o lugar mais fácil para comprar e vender criptomoedas. Registre-se agora e comece a trabalhar!",
		view_more: "Veja mais",
		p1: "A principal plataforma de negociação de ativos digitais do mundo",
		p2: "Fornecer serviços seguros e confiáveis de negociação e gerenciamento de ativos digitais a milhões de usuários em mais de 130 países em todo o mundo",
		p3: "seguro e confiável",
		p4: " Anos de experiência em serviços financeiros de ativos digitais",
		p5: "Arquitetura distribuída profissional e sistema de ataque anti-DDOS",
		p6: "Layout ecológico global",
		p7: "Centros de serviços comerciais localizados em mais de 50 países",
		p8: "Criação de um ecossistema de blockchain com várias formas de negócios como um todo",
		p9: "o usuário vem em primeiro lugar",
		p10: "Estabelecimento de um mecanismo de pagamento antecipado",
		p11: "Estabelecimento de um fundo de proteção ao investidor",
		p12: "Comece sua jornada com a moeda digital hoje mesmo!",
		p13: "Fornecer a você a maneira mais conveniente de negociar moeda digital",
		p14: "Terminal multiplataforma de negociação a qualquer hora, em qualquer lugar",
		p15: "Abrangendo várias plataformas de iOS, Android e Windows, com suporte a funções de serviço completo",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "Registros DeFi",
		label_issue_price: "Preço de emissão",
		label_number: "Assinatura",
		label_money: "Pagamentos totais",
		label_time: "Período de assinatura",
	},
	legal: {
		banner_p1: "C2C: compre e venda moedas digitais rapidamente",
		banner_p2: "A C2C é uma plataforma de comércio peer-to-peer, em que os usuários podem interagir diretamente com os comerciantes na C2C.",
		banner_p3: "Compre e venda Bitcoin e outras moedas digitais pelo preço e método de pagamento desejados.",
		table_legal: "Pregão",
		table_order: "Pedidos de compra e venda de moedas",
		buy: "Compra",
		sell: "Oferta para venda",
		steps_title: "Três etapas para concluir uma transação P2P e comprar moedas sem taxas!",
		steps_tit1: "Selecione seu comerciante",
		steps_tit2: "Recebimento/pagamento de vendedores",
		steps_tit3: "Emissão/recebimento de criptomoeda",
		steps_p1: "Escolha um anúncio que corresponda ao preço de sua preferência e à forma de pagamento.",
		steps_p2: "Insira a quantidade de compra/venda e o valor da transação para concluir o pedido.",
		steps_p3: "Receber/pagar o vendedor por meio do método de pagamento sugerido.",
		steps_p4: "Depois de concluir a transação fiduciária, no TCOIN P2P, clique no botão",
		steps_p5: "[Transferido, notifique o vendedor]. A TCOIN não cobrará nenhuma taxa.",
		steps_p6: "A moeda será liberada quando o vendedor receber o pagamento. Vá para a conta fiduciária para visualizar",
		steps_p7: "Você recebe a moeda. Depois de confirmar o recebimento do dinheiro, você poderá vê-lo na página da TCOIN P2P",
		steps_p8: "Sobre a emissão de criptomoedas para os compradores.",
	},
	lever: {
		market_trand: "Transação a preço de mercado",
		limit_trand: "Limitar o comércio",
		tip: "1 lote equivale a 1BTC",
		handicap: "Encruzilhada",
		whole_transaction: "Transação em todo o site",
		tab1: "Posição atual",
		tab2: "Posição inteira",
		tab3: "Todos os pedidos pendentes",
		tab4: "Fechar uma posição inteira",
		tab5: "Retirada de todos os pedidos",
		tab6: "Fechar a posição com um clique",
		close_position: "Determinação do fechamento da posição",
		position1: "Fechar uma posição inteira",
		position2: "Somente pedidos múltiplos",
		position3: "Confiar apenas em ordens vazias (expressão idiomática); sem qualquer evidência",
		positionState1: "Abertura de posição pendente",
		positionState2: "Aguardando o fechamento de uma posição",
		positionState3: "Feito",
	},
	login: {
		tip1: "Bem-vindo à TCOIN",
		tip2: "Negocie a qualquer hora, em qualquer lugar",
		tip3: "Bem-vindo à TCOIN",
		tip4: "Bem-vindo à TCOIN",
	},
	second: {
		transfer_model: "Padrão de negociação",
		open_number: "Número de posições abertas",
		open_time: "Horário de abertura",
		second_account: "Conta de contrato de segundos",
		profit_rate: "Lucratividade",
		buy_long: "Comprar (preços)",
		buy_short: "Comprar e vender para baixo (preços)",
		transfering: "No processo de fazer um acordo",
		closed_position: "posição fechada",
	},
	trade: {
		transfer_amount: "faturamento",
		buy: "adesão",
		sell: "alcance (um preço em um leilão)",
		now_entrust: "Comissões atuais",
		all_entrust: "Toda a comissão",
		history_entrust: "Comissões históricas",
	},
	personal: {
			tit: "Informações básicas",
			tit1: "Pessoas",
			tit2: "Nível de segurança",
			state1: "Vigoroso",
			nav1: "Verificação de e-mail",
			nav2: "Meu código de convite",
			nav3: "Senha de login",
			nav4: "Senha de negociação",
			nav5: "Configurações de cobrança de pagamento",
			nav6: "Autenticar",
			nav7: "Feedback do usuário",
			modify: "Modificações",
			goSetting: "Vá em frente e configure",
			login_psd: "Alterar a senha de login",
			trans_psd: "Alterar a senha de negociação",
			collection: "Configurações de cobrança de pagamento",
			authentication: "Autenticar",
			feedback: "Feedback do usuário"
	},
	form: {
		label_oldPsd: "Senha antiga",
		place_oldPsd: "Digite a senha antiga",
		label_newPsd: "Nova senha",
		place_newPsd: "Digite uma nova senha",
		label_crmPsd: "Confirmar senha",
		place_crmPsd: "Digite a nova senha novamente",
		label_bankType: "Método de pagamento",
		place_bankType: "Selecione um método de pagamento",
		label_bank: "Cartão de caixa eletrônico",
		label_realName:"Nome verdadeiro e sobrenome",
		palce_realName:"Digite seu nome verdadeiro",
		label_bankName:"Abertura de conta bancária",
		palce_bankName:"Digite a conta bancária",
		label_bankBranch:"Abertura de conta Subfilial",
		palce_bankBranch:"Informe a agência onde a conta foi aberta",
		label_bankNumber:"Número do cartão de caixa eletrônico",
		palce_bankNumber:"Digite o número de seu cartão bancário",
		label_cardNumber: "Número do certificado",
		place_cardNumber: "Digite seu número de identificação",
		card_img1: "Clique para carregar a página inicial do ID",
		card_img2: "Clique para carregar a página de informações do documento",
		card_img_tip:"As informações enviadas serão criptografadas e armazenadas de forma isolada, e as informações enviadas serão automaticamente analisadas pelo sistema, portanto, sinta-se à vontade para preenchê-las.",
		label_feedbackType: "Tipo de problema",
		place_feedbackType: "Selecione o tipo de pergunta",
		feedbackType0: "Relacionado à retirada",
		feedbackType1: "Relacionado a blockchain",
		label_feedbackTitle: "Legenda",
		place_feedbackTitle: "Digite um título",
		label_feedbackCont: "descrições",
		place_feedbackCont: "Digite uma descrição",
		place_currency: "Selecione a moeda",
		place_account: "Selecione uma conta",
		label_acrrency: "Tipo de moeda",
		label_number: "Quantidades",
		label_record: "Recorde (em esportes etc.)",
		label_time: "Horários",
		label_available: "Usabilidade",
		label_freeze: "Congelamento (empréstimo, salário, preço etc.)",
		label_total: "Montante de",
		label_operate: "Equipamento",
		label_transfer_currency: "Moeda da transferência",
		label_transfer_account: "Conta de transferência",
		label_account_balance: "Saldo da conta",
		label_transfer_number: "Número de transferências",
		place_transfer_number: "Digite o número de transferências",
		label_buy_price: "Preço da oferta",
		label_buy_number: "Volume de compras",
		place_buy_market: "Compre pelo melhor preço de mercado",
		label_sell_price: "Taxa de venda",
		label_sell_number: "Volume de vendas",
		place_sell_market: "Vender pelo melhor preço do mercado",
		product_name: "Cloreto de dietilamônio",
		buy_total: "Número total de compras",
		earnings: "Ganho acumulado",
		product_cycle: "Ciclo do produto",
		state: "Estado das coisas",
		time: "Horários",
		place_phone_email: "Caixa postal do telefone celular",
		login: "Entrar",
		register: "Registro",
		label_price: "Preços",
		place_price: "Digite um preço",
		label_multiple: "Fator",
		place_multiple: "Selecione o multiplicador",
		label_hand: "Número ordinal",
		place_hand: "Digite o número de lotes",
		label_volume: "Volume 24 horas",
		daily_fluctuations: "aumento ou queda no preço por dia",
		label_quotes: "últimos três dias",
		label_toTrade: "Vá em frente e faça um acordo",
		available_amount: "Valor disponível",
		label_transfer_psd: "Senha de negociação",
		palce_transfer_psd: "Digite a senha da transação",
		buy_long: "Suborno",
		sell_short: "Vender a descoberto",
		elail_login: "Login de e-mail",
		phone_login: "Login móvel",
		label_email: "Caixa de entrada",
		place_email: "Digite seu endereço de e-mail",
		label_phone: "Número de telefone celular",
		place_phone: "Digite seu número de telefone celular",
		label_password: "Criptográfico",
		place_password: "Digite sua senha",
		remember_psd: "Lembrar uma senha",
		no_account: "Ainda não tenho uma conta.？",
		forget_psd: "Esqueceu a senha?？",
		label_verify: "CAPTCHA, um tipo de teste de desafio-resposta (computação)",
		place_Verify: "Digite o código de verificação",
		send: "Envio",
		label_invite: "Código de convite",
		place_invite: "Digite o código do convite",
		have_account: "Ter uma conta",
		go_login: "Ir para o login",
		label_linkName: "Nome da cadeia",
		label_recharge_address: "Endereço de retirada de moedas",
		place_recharge_address: "Digite o endereço de retirada",
		label_recharge_number: "Número de moedas",
		place_recharge_number: "Digite o número de moedas",
		label_wallet_address: "Endereço da carteira",
		place_wallet_address: "Digite o endereço de sua carteira",
		label_withdraw_number: "Número de moedas retiradas",
		place_withdraw_number: "Digite o número de moedas a serem retiradas",
		place_min_withdraw: "Número mínimo de moedas a serem retiradas",
		label_remark: "Nota",
		place_remark: "Digite uma nota",
		label_transPsd: "Senha de negociação",
		place_transPsd: "Digite a senha da transação",
		message1: "Digite o valor",
		message2: "Saldo insuficiente da conta",
		label_amount: "Soma de dinheiro",
		place_amount: "Digite o valor",
	},
	table: {
		label_time: "Horários",
		label_title: "Contraparte da transação",
		label_direction: "Orientações",
		label_price: "Preços",
		label_number: "Quantidades",
		label_volume: "Faturamento",
		label_operate: "Equipamento",
		label_acrrency: "Tipo de moeda",
		label_last_price: "Preço mais recente",
		label_ncrease: "Aumento e queda de ações em um dia de negociação",
		label_height_price: "Ppreço mais alto",
		label_low_price: "Preço mais baixo",
		label_quantity: "Volume 24 horas",
		label_buyPrice: "Preço de compra",
		label_finalPrice: "Preço alcançado em um leilão",
		label_expectedProfit: "Lucros e perdas projetados",
		label_countDown: "Contagem regressiva",
		sell: "Exibir ou ostentar",
		buy: "Compra",
		label_type: "Tipologia",
		label_open_price: "Preço de abertura",
		label_now_price: "Preço atual",
		label_bail: "Margem (em negociações de derivativos)",
		label_stop_profit: "Preço de take-profit",
		label_stop_loss: "Preço de mercado",
		label_open_time: "Horário de abertura",
		label_fee: "Taxa de manuseio",
		label_overnight_fee: "Carga noturna",
		label_profit_loss: "Crescente e decrescente",
		label_total_amount: "Valor total",
		label_order_time: "Comprimento do pedido",
		label_order_income: "Receita de pedidos",
		label_state: "Estado das coisas",
		label_endTime: "Hora final",
		state0: "Aguardando aprovação",
		state1: "Feito",
	},
	params: {
		recordType1: "Despesas",
		recordType2: "Recarga (dinheiro em um cartão)",
		ieoState0: "Completo",
		ieoState1: "Pré-venda",
		ieoState2: "Assinatura em andamento",
		ieoState3: "Fechado",
		depositState0: "Em andamento.",
		depositState1: "Aguarde ansiosamente",
		lockStateType0: "Em execução",
		lockStateType1: "Fechado",
		lockStateType2: "Cancelado",
	},
	city: {
        albania: "Albânia",
        algeria: "Argélia",
        argentina: "Argentina",
        armenia: "Armênia",
        australia: "Austrália",
        pakistan: "Paquistão",
        austria: "Áustria",
        bahrain: "Bahrein",
        belgium: "Bélgica",
        bosnia_and_Herzegovina: "Bósnia e Herzegovina",
        brazil: "Brasil",
        brunei: "Brunei",
        bulgaria: "Bulgária",
        cambodia: "Camboja",
        canada: "Canadá",
        cameroon: "Camarões",
        chile: "Chile",
        colombia: "Colômbia",
        costa_Rica: "Costa Rica",
        croatia: "Croácia",
        cyprus: "Chipre",
        czech_Republic: "República Tcheca",
        denmark: "Dinamarca",
        dominican_Republic: "República Dominicana",
        egypt: "Egito",
        estonia: "Estônia",
        ethiopia: "Etiópia",
        finland: "Finlândia",
        france: "França",
        georgia: "Geórgia",
        germany: "Alemanha",
        ghana: "Gana",
        greece: "Grécia",
        guyana: "Guiana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungria",
        iceland: "Islândia",
        ireland: "Irlanda",
        italy: "Itália",
        india: "Índia",
        indonesia: "Indonésia",
        israel: "Israel",
        iran: "Irã",
        iraq: "Iraque",
        japan: "Japão",
        kazakstan: "Cazaquistão",
        kenya: "Quênia",
        korea: "Coreia",
        kuwait: "Kuwait",
        kyrgyzstan: "Quirguistão",
        laos: "Laos",
        latvia: "Letônia",
        lithuania: "Lituânia",
        luxembourg: "Luxemburgo",
        macao_China: "Macau, China",
        macedonia: "Macedônia",
        malaysia: "Malásia",
        malta: "Malta",
        mexico: "México",
        moldova: "Moldávia",
        monaco: "Mônaco",
        mongolia: "Mongólia",
        montenegro: "Montenegro",
        morocco: "Marrocos",
        myanmar: "Mianmar",
        netherlands: "Países Baixos",
        new_Zealand: "Nova Zelândia",
        nepal: "Nepal",
        nigeria: "Nigéria",
        norway: "Noruega",
        oman: "Omã",
        palestine: "Palestina",
        panama: "Panamá",
        paraguay: "Paraguai",
        peru: "Peru",
        philippines: "Filipinas",
        poland: "Polônia",
        portugal: "Portugal",
        puerto_Rico: "Porto Rico",
        qatar: "Catar",
        romania: "Romênia",
        russia: "Rússia",
        republic_of_Trinidad_and_Tobago: "República de Trinidad e Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arábia Saudita",
        serbia: "Sérvia",
        singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovênia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka:"Sri Lanka",
		sweden:"Suécia",
		switzerland:"Suíça",
		taiwan_China:"Taiwan, China",
		tajikistan:"Tajiquistão",
		tanzania:"Tanzânia",
		thailand:"Tailândia",
		turkey:"Turquia",
		turkmenistan:"Turcomenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "Emirados Árabes Unidos",
		united_Kingdom: "Reino Unido",
		united_States: "Estados Unidos",
		uzbekistan: "Uzbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietnã",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "Bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Cayman",
		central_African_Republic: "República Centro-Africana",
		chad: "Chade",
		comoros: "Comores",
		the_Republic_of_the_Congo: "República do Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "República Democrática do Congo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Groenlândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagáscar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "República do Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Serra Leoa",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Essuatíni",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens Americanas",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "Zimbábue",
		china:"China",
    }
};
