export default {
	adds:{
		rechargePic: "Payment Receipt",
		loan_state_4: "Fail",
		loan_state_3: "Paid back",
		loan_state_2: "Disbursed",
		loan_state_1: "Awaiting approval",
		repay_state_2: "Paid back",
		repay_state_1: "Non-returned money",
		repay_create: "Loan period",
		repay_state: "State of affairs",
		repay_times: "Phase",
		repay_time: "Repayment period",
		repay_money: "Total repayment",
		repay_total: "Loan amount",
		repay_pay: "Repayment Records",
		repay: "Loan records",
		loan_pic4: "ID photo",
		loan_pic3: "Bank statement",
		loan_pic2: "Income certificate",
		loan_pic1: "Certificate of property ownership",
		loan_pic: "Credit Loan (please make sure the picture is clearly visible)",
		loan_pics: "Identification form",
		loan_pics_view: "Click to view",
		loan_repay_method_1: "Lump sum principal and interest repayment" ,
		loan_repay: "Repayment method",
		loan_apr: "Daily interest rate",
		loan_time: "Loan cycle",
		loan_type_1: "Sky",
		loan_type_2: "Moon",
		loan_money: "Loan amount",
		loan: "Student loan",
		one: "Account Settings",
		two: "Collection Settings",
		three: "Identity Settings",
		four: "User feedback",
		five: "Log out",
		verifySuccess: "Verified",
		verifyFailed: "Not Verify",
		verifyPass: "Verify Password",
		notice: "NOTICE",
		logout_tip: "Are you sure to Logout?",
		day: "Day",
	},
	types: [
		{ v: 5, name: 'financial account' },
		{ v: 4, name: 'Extreme Contracts Account' },
		{ v: 3, name: 'fiat currency account' },
		{ v: 2, name: 'contract account' },
		{ v: 1, name: 'Coin Account' },
	],
	billTypes: [
		{v: 101, name: 'Recharge'},
		{v: 102, name: 'Deposit'},
		{v: 103, name: 'Freeze'},
		{v: 104, name: 'Thaw'},
		{v: 105, name: 'Deposit'},
		{v: 106, name: 'Deposit'},
		{v: 201, name: 'Withdrawal freeze'},
		{v: 202, name: 'Withdrawal'},
		{v: 203, name: 'Withdrawal succeeded'},
		{v: 204, name: 'Withdrawal failed'},
		{v: 205, name: 'Withdrawal handling fee'},
		{v: 206, name: 'Transfer out'},
		{v: 207, name: 'Transferred in'},
		{v: 208, name: 'Currency transfer out'},
		{v: 209, name: 'Currency transfer in'},
		{v: 301, name: 'Contract handling fee'},
		{v: 302, name: 'Contract Revenue'},
		{v: 303, name: 'Contract loss'},
		{v: 311, name: 'Option Purchase'},
		{v: 312, name: 'Option earnings'},
		{v: 313, name: 'Option Return'},
		{v: 314, name: 'Option Fee'},
		{v: 315, name: 'Coin buy freeze'},
		{v: 316, name: 'Coin buy deduction'},
		{v: 317, name: 'Coin buy back'},
		{v: 318, name: 'Coin buy-in received'},
		{v: 319, name: 'Coin buy-in received!'},
		{v: 320, name: 'Coin sell freeze'},
		{v: 321, name: 'Coins sold deducted'},
		{v: 322, name: 'Sell coins back'},
		{v: 323, name: 'Coins sold to account'},
		{v: 324, name: 'Coins sold to the account!'},
		{v: 325, name: 'Coin pending order fee!'},
		{v: 401, name: 'Mining machine added'},
		{v: 402, name: 'Mining Machine Return'},
		{v: 403, name: 'Mining Machinery Revenue'},
		{v: 404, name: 'Miner Exit'},
		{v: 405, name: 'Mining machine exit fee'},
		{v: 501, name: 'Blind Box Purchase'},
		{v: 502, name: 'Blind Box Revenue'},
	],
	common: {
		confirm: "Confirm",
		cancel: "Cancel",
		submit: "Submit ",
		reset: "Resetting",
		next: "Next step",
		review: "in review",
		copy_success: "Replicating Success！",
		copy_error: "Copy failed！",
		more: "More",
		success: "Success",
		fail: "Fail",
		place_input: "Please enter content",
		form_empty: "There are currently no records available",
		download: "Download",
		history_record: "Historic records",
		closing_position: "Closing position",
		tip: "Tip",
		forget: "Forgot Password",
		all: "All",
	},
	message: {
		cancel_order: "Whether to revoke the delegation?",
		stop_out: "Whether to forcibly close the position?",
	},
	header: {
		nav1: "Fait",
		nav2: "Exchange",
		nav3: "Futures",
		nav4: "Seconds Futures",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "Assets",
		setting_account: "Account Settings",
		setting_collection: "Collection settings",
		setting_authentication: "Identity settings",
		setting_feedback: "Feedback",
		logout: "Log out",
	},
	footer: {
		tit1: "About us",
		tit2: "User Support",
		tit3: "Help Center",
		tit4: "Contact Us",
		tit5: "Online Service",
		p1: "Platform Introduction",
		p2: "Project publicity",
		p3: "Privacy Policy",
		p4: "How to register through email",
		p5: "How to Authenticate with Real Name",
		p6: "How to set a transaction password",
		p7: "What is recharge and how to recharge",
		p8: "What is coin withdrawal and how to withdraw it",
		p9: "New Guide to Coin Trading",
	},
	assets: {
		asset_verview: "Asset Overview",
		asset_verview_conversion: "Asset Overview Conversion",
		account_assets: "Account assets",
		financial_records: "Financial records",
		tab_trade: "Exchange",
		tab_legal: "Fiat",
		tab_lever: "Futures",
		tab_second: "Second Futures",
		tab_financial: "Financial",
		tab_transfer: "Transfer",
		recharge: "Recharge",
		withdraw: "Withdrawal",
		currency_transfer: "Currency transfer",
		asset_transfer: "Asset transfer",
		transfer_record: "Transfer Record",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFi record",
		reference_annualization: "Reference annualization",
		state: "state",
		term: "time limit",
		day: "day",
		buy: "buy",
		min_amount: "Minimum amount",
		daily: "Daily Production",
		waiting_time: "Waiting Time",
		time: "Time",
		everyday: "Every Day",
		escrow_funds: "Escrow funds",
		returnType: "Expiration return",
		early_withdrawal: "Advance withdrawal",
		estimated_income: "Estimated revenue",
		available_assets: "Available assets"
	},
	home: {
		download: "TCOIN download",
		ios_download: "IOS download",
		android_download: "Android Download",
		banner_p1: "Quickly activate your investment portfolio",
		banner_p2: "Quickly activate your",
		banner_p3: "Cryptographic Portfolio",
		banner_p4: "TXCOIN is the easiest place to buy and sell cryptocurrencies. Register and start using now",
		view_more: "See more",
		p1: "The world's leading digital asset trading platform",
		p2: "Deliver secure, trusted digital asset trading and asset management services to millions of users in more than 130 countries worldwide",
		p3: "Safe and reliable",
		p4: " Years of experience in digital asset financial services",
		p5: "Professional distributed architecture and DDOS attack prevention system",
		p6: "Global ecological layout",
		p7: "50 Establishing Localized Trading Service Centers in Multiple Countries",
		p8: "Building a blockchain ecosystem that integrates multiple business forms",
		p9: "User First",
		p10: "Establish a mechanism for advance compensation",
		p11: "Establishing an investor protection fund",
		p12: "Start your digital currency journey now",
		p13: "Provide you with the most convenient digital currency trading method",
		p14: "Multi platform terminal transactions anytime, anywhere",
		p15: "Covering multiple platforms such as iOS, Android, and Windows, supporting full business functionality",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "DeFi record",
		label_issue_price: "Issuance price",
		label_number: "Subscription quantity",
		label_money: "Total payment",
		label_time: "Subscribe time",
	},
	legal: {
		banner_p1: "C2C:Quick buying and selling of digital currency",
		banner_p2: "C2CC2C is a peer-to-peer trading platform where users can directly communicate with merchants",
		banner_p3: "Buy and sell Bitcoin and other digital currencies at the desired price and payment method。",
		table_legal:"trading floor",
		table_order:"Buy and sell currency orders",
		buy: "purchase",
		sell: "sell",
		steps_title: "Complete P2P transactions in three steps, buy coins with zero handling fee！",
		steps_tit1: "Choose your merchant",
		steps_tit2: "Collection/Payment to Seller",
		steps_tit3: "Distribute/receive cryptocurrency",
		steps_p1: "Choose advertisements that match your preferences in terms of price and payment method。",
		steps_p2: "Enter the purchase/sale quantity and transaction amount to complete the order。",
		steps_p3: "Collect/make payment to the seller through the suggested payment method。",
		steps_p4: "After completing the legal currency transaction, click on TCOIN P2P",
		steps_p5: "[Transfer has been made, notify seller]。TCOIN No fees will be charged。",
		steps_p6: "After receiving the payment, the seller will issue currency. Go to the legal currency account to check",
		steps_p7: "The currency you received. After confirming the payment, on TCOIN P2P",
		steps_p8: "Distribute cryptocurrency to buyers。",
	},
	lever: {
		market_trand: "Market value trading",
		limit_trand: "Limit trading",
		tip: "1 Hand equals 1BTC",
		handicap: "Handicap",
		whole_transaction: "Whole",
		tab1: "Currently holding",
		tab2: "All held",
		tab3: "All pending orders",
		tab4: "Full liquidation",
		tab5: "Cancel all orders",
		tab6: "One click closing position",
		close_position: "Confirm closing position",
		position1: "Full liquidation",
		position2: "Only relying on multiple documents",
		position3: "with Empty order ",
		positionState1: "To be opened",
		positionState2: "Open positions",
		positionState3: "Completed",
	},
	login: {
		tip1: "Welcome to login TCOIN",
		tip2: "Trade anytime, anywhere",
		tip3: "Welcome to register TCOIN",
		tip4: "Welcome to TCOIN",
	},
	second: {
		transfer_model: "Transaction mode",
		open_number: "Opening quantity",
		open_time: "Open Time",
		second_account: "Second contract account",
		profit_rate: "profit rate",
		buy_long: "Rising",
		buy_short: " Buy Falling",
		transfering: "In transaction",
		closed_position: "Closed position",
	},
	trade: {
		transfer_amount: "a turnover",
		buy: "buy",
		sell: "Sell",
		now_entrust: "Current delegation",
		all_entrust: "Full delegation",
		history_entrust: "history entrust",
	},
	personal: {
			tit: "Basic information",
			tit1: "Person",
			tit2: "Security level",
			state1: "High",
			nav1: "Email verification",
			nav2: "My invitation code",
			nav3: "Login password",
			nav4: "Transaction password",
			nav5: "Collection settings",
			nav6: "Identity authentication",
			nav7: "Feedback",
			modify: "Modify",
			goSetting: "Set",
			login_psd: "Change login password",
			trans_psd: "Change transaction password",
			collection: "Collection settings",
			authentication: "Identity authentication",
			feedback: "Feedback",
	},
	form: {
		label_oldPsd: "Old password",
		place_oldPsd: "Please enter your old password",
		label_newPsd: "New Password",
		place_newPsd: "Please enter a new password",
		label_crmPsd: "Confirm Password",
		place_crmPsd: "Please enter the new password again",
		label_bankType: "Payment method",
		place_bankType: "Please select the payment method",
		label_bank: "Bank Card",
		label_realName:"Name",
		palce_realName:"Please enter your real name",
		label_bankName:"BANK",
		palce_bankName:"Please enter the account opening bank",
		label_bankBranch:"Opening branch",
		palce_bankBranch:"Please enter the account opening branch",
		label_bankNumber:"Bank card number",
		palce_bankNumber:"Please enter your bank card number",
		label_cardNumber: "ID Number",
		place_cardNumber: "Please enter your ID number",
		card_img1: "Click to upload the ID homepage",
		card_img2: "Click to upload the ID information page",
		card_img_tip:"The information you submit will be encrypted, isolated, and stored. The submitted information will be automatically reviewed by the system. Please rest assured to fill it out.",
		label_feedbackType: "Question type",
		place_feedbackType: "Please select a question type",
		feedbackType0: "Withdrawal related",
		feedbackType1: "Blockchain related",
		label_feedbackTitle: "Title",
		place_feedbackTitle: "Please enter a title",
		label_feedbackCont: "Describe",
		place_feedbackCont: "Please enter a description",
		place_currency: "Please select a currency",
		place_account: "Please select an account",
		label_acrrency: "Currency",
		label_number: "number",
		label_record: "record",
		label_time: "time",
		label_available: "usable",
		label_freeze: "Frozen",
		label_total: "Convert into",
		label_operate: "operate",
		label_transfer_currency: "Transfer currency",
		label_transfer_account: "Transfer account",
		label_account_balance: "Account balance",
		label_transfer_number: "Transfer quantity",
		place_transfer_number: "Please enter the transfer quantity",
		label_buy_price: "buying rate",
		label_buy_number: "buying volume",
		place_buy_market: "Buy at the best market price",
		label_sell_price: "selling rate",
		label_sell_number: "selling volume",
		place_sell_market: "Sell at the best market price",
		product_name: "product name",
		buy_total: "Total quantity purchased",
		earnings: "cumulative gain",
		product_cycle: "product cycle",
		state: "state",
		time: "time",
		place_phone_email: "Mobile email",
		login: "log on",
		register: "REGISTRATION",
		label_price: "price",
		place_price: "Please enter the price",
		label_multiple: "multiple",
		place_multiple: "Please select multiple",
		label_hand: " hand number",
		place_hand: "Please enter the number of hands",
		label_volume: "24H Vol",
		daily_fluctuations: "Daily rise and fall",
		label_quotes: "Nearly 3 days",
		label_toTrade: "To trade",
		available_amount: "Available amount",
		label_transfer_psd: "Password",
		palce_transfer_psd: "Please enter the transaction password",
		buy_long: "Long buying",
		sell_short: "short selling",
		elail_login: "Email login",
		phone_login: "Mobile login",
		label_email: "Email",
		place_email: "Please enter your email address",
		label_phone: "Phone",
		place_phone: "Please enter your phone number",
		label_password: "Password",
		place_password: "Please enter the password",
		remember_psd: "remember password",
		no_account: "No account yet？",
		forget_psd: "Forgot Password？",
		label_verify: "Verification Code",
		place_Verify: "Please enter the verification code",
		send: "sending",
		label_invite: "Invitation Code",
		place_invite: "Please enter the invitation code",
		have_account: "Have an account？",
		go_login: "Sign Up",
		label_linkName: "Chain Name",
		label_recharge_address: "Withdrawal address",
		place_recharge_address: "Please enter the withdrawal address",
		label_recharge_number: "Charge quantity",
		place_recharge_number: "Please enter the quantity of coins to be recharged",
		label_wallet_address: "Wallet address",
		place_wallet_address: "Please enter the wallet address",
		label_withdraw_number: "Withdrawal quantity",
		place_withdraw_number: "Please enter the withdrawal quantity",
		place_min_withdraw: "Minimum withdrawal quantity",
		label_remark: "Remarks",
		place_remark: "Please enter a comment",
		label_transPsd: "Transaction password",
		place_transPsd: "Please enter the transaction password",
		message1: "Please enter the amount",
		message2: "Insufficient account balance",
		label_amount: "Amount",
		place_amount: "Please enter the amount",
	},
	table: {
		label_time: "Time",
		label_title: "Trading pair",
		label_direction: "Direction",
		label_price: "Price",
		label_number: "Number",
		label_volume: "Trading volume",
		label_operate: "Operate",
		label_acrrency: "Currency",
		label_last_price: "Latest price",
		label_ncrease: "Gain",
		label_height_price: "Highest price",
		label_low_price: "Latest price",
		label_quantity: "24H Vol",
		label_buyPrice: "Purchase price",
		label_finalPrice: "Transaction price",
		label_expectedProfit: "Expected profit and loss",
		label_countDown: "countdown",
		sell: "Sell",
		buy: "Buy",
		label_type: "types of",
		label_open_price: "Opening price",
		label_now_price: "Current price",
		label_bail: "Security deposit",
		label_stop_profit: "Stop profit price",
		label_stop_loss: "Stop loss price",
		label_open_time: "Opening time",
		label_fee: "Handling charge",
		label_overnight_fee: "Overnight fee",
		label_profit_loss: "profit and loss",
		label_total_amount: "Total amount",
		label_order_time: "Order duration",
		label_order_income: "Revenue",
		label_state: "State",
		label_endTime: "End Time",
		state0: "Pending",
		state1: "Completed",
	},
	params: {
		recordType1: "disburse",
		recordType2: "Recharge money",
		ieoState0: "whole",
		ieoState1: "In pre-sale",
		ieoState2: "Subscription in progress",
		ieoState3: "Already ended",
		depositState0: "In progress",
		depositState1: "Stay tuned",
		lockStateType0: "In operation",
		lockStateType1: "Ended",
		lockStateType2: "Canceled",
	},
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
