export default {
	adds:{
		loan_state_4: "Facasar ",
		loan_state_3: "Devuelto",
		loan_state_2: "Desembolsado",
		loan_state_1: "En espera de aprobación",
		repay_state_2: "Devuelto",
		repay_state_1: "Impago",
		repay_create: "Periodo del préstamo",
		repay_state: "Situación",
		repay_times: "Fase",
		repay_time: "Periodo de amortización",
		repay_money: "Reembolso total",
		repay_total: "Importe del préstamo",
		repay_pay: "Registros de reembolso",
		repay: "Registros de préstamos",
		loan_pic4: "Foto ID",
		loan_pic3: "Extracto bancario",
		loan_pic2: "Prueba de ingresos",
		loan_pic1: "Certificado de propiedad",
		loan_pic: "Préstamos de crédito (por favor, asegúrese de que las imágenes son claramente visibles)",
		loan_pics: "Formulario de identificación",
		loan_pics_view: "Haga clic para ver",
		loan_repay_method_1: "Reembolso a tanto alzado de capital e intereses" ,
		loan_repay: "Método de reembolso",
		loan_apr: "Tipo de interés diario",
		loan_time: "Ciclo de préstamos",
		loan_type_1: "Día",
		loan_type_2: "Luna",
		loan_money: "Importe del préstamo",
		loan: "Préstamo estudiantil",
		one: "Configuración de la cuenta",
		two: "Ajustes de recogida",
		three: "Configuración de la identidad",
		four: "Comentarios de los usuarios",
		five: "Cerrar sesión",
		verifySuccess: "Certificación aprobada",
		verifyFailed: "No certificado",
		verifyPass: "Contraseña de verificación",
		notice: "Aviso",
		logout_tip: "¿Estás seguro de que quieres cancelar?",
		day: "Durante el día",
	},
	types: [
		{ v: 5, name: 'Cuenta financiera' },
		{ v: 4, name: 'Cuenta Extreme Contracts' },
		{ v: 3, name: 'Cuenta en moneda fiduciaria' },
		{ v: 2, name: 'Cuenta del contrato' },
		{ v: 1, name: 'Cuenta de monedas' },
	],
	billTypes: [
		{v: 101, name: 'Recarga (dinero en una tarjeta)'},
		{v: 102, name: 'Depósito (por ejemplo, en una cuenta bancaria)'},
		{v: 103, name: 'Congelación (préstamo, salario, precio, etc.)'},
		{v: 104, name: 'Descongelar'},
		{v: 105, name: 'Depósito (por ejemplo, en una cuenta bancaria)'},
		{v: 106, name: 'Depósito (por ejemplo, en una cuenta bancaria)'},
		{v: 201, name: 'Congelación de retiradas'},
		{v: 202, name: 'Retirar fondos'},
		{v: 203, name: 'Retirada con éxito'},
		{v: 204, name: 'No retirar fondos'},
		{v: 205, name: 'Tasa de retirada'},
		{v: 206, name: 'Transferir'},
		{v: 207, name: 'Pasar a'},
		{v: 208, name: 'Transferencia de monedas'},
		{v: 209, name: 'Transferencia de monedas'},
		{v: 301, name: 'Tasa de tratamiento de monedas'},
		{v: 302, name: 'Ingresos contractuales'},
		{v: 303, name: 'Pérdida del contrato'},
		{v: 311, name: 'Compra de opciones'},
		{v: 312, name: 'Ganancia de la opción'},
		{v: 313, name: 'Opción Retorno'},
		{v: 314, name: 'Tasa de tramitación de opciones'},
		{v: 315, name: 'Congelación de la compra de monedas'},
		{v: 316, name: 'Deducción por compra de monedas'},
		{v: 317, name: 'Devolución de la compra de monedas'},
		{v: 318, name: 'Ingreso de monedas en cuenta'},
		{v: 319, name: 'Ingreso de monedas en cuenta'},
		{v: 320, name: 'Congelación de la venta de monedas'},
		{v: 321, name: 'Deducción por venta de monedas'},
		{v: 322, name: 'Devolución de monedas'},
		{v: 323, name: 'Venta de monedas a cuenta'},
		{v: 324, name: 'Venta de monedas a cuenta'},
		{v: 325, name: 'Tasa de tratamiento de monedas'},
		{v: 401, name: 'Los mineros se unen'},
		{v: 402, name: 'Retorno de los mineros'},
		{v: 403, name: 'Ingresos por máquinas mineras'},
		{v: 404, name: 'Salida de máquinas mineras'},
		{v: 405, name: 'Tasa de salida de minas'},
		{v: 501, name: 'Compra de cajas ciegas'},
		{v: 502, name: 'Ingresos de la caja ciega'},
	],
	common: {
		confirm: "Reconocer",
		cancel: "Anulaciones",
		submit: "Presentar (un informe, etc.)",
		reset: "Revisión",
		next: "El siguiente paso",
		review: "En revisión",
		copy_success: "Copiar con éxito！",
		copy_error: "Fallo de reproducción！",
		more: "Más",
		success: "éxitos",
		fail: "Fracasar (por ejemplo, experimentos)",
		place_input: "Introduzca el contenido",
		form_empty: "Ningún registro",
		download: "Descargando",
		history_record: "Registro histórico",
		closing_position: "Cerrar una posición",
		tip: "Llamar la atención sobre algo",
		forget: "¿Ha olvidado su contraseña?",
		all: "Completo",
	},
	message: {
		cancel_order: "Si se revoca la comisión?",
		stop_out: "Si se fuerza o no el cierre de la posición?",
	},
	header: {
		nav1: "Comercio de divisas",
		nav2: "Comercio de monedas",
		nav3: "Negociación de contratos",
		nav4: "Segundo contrato (en deportes)",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "Mis activos",
		setting_account: "Configuración de la cuenta",
		setting_collection: "Ajustes de recogida",
		setting_authentication: "Configuración de la identidad",
		setting_feedback: "Comentarios de los usuarios",
		logout: "Cerrar sesión",
	},
	footer: {
		tit1: "Quiénes somos",
		tit2: "Asistencia al usuario",
		tit3: "Centro de ayuda",
		tit4: "Contacte con nosotros",
		tit5: "Servicio de atención al cliente en línea",
		p1: "Plataforma Introducción",
		p2: "Anuncio de proyecto",
		p3: "Política de privacidad",
		p4: "Cómo inscribirse por correo electrónico",
		p5: "Cómo autentificar su nombre",
		p6: "Cómo establecer una contraseña de negociación",
		p7: "Qué es la reposición de monedas, cómo reponer monedas",
		p8: "Qué es retirar monedas, cómo retirar monedas",
		p9: "Guía para principiantes en el comercio de monedas",
	},
	assets: {
		asset_verview: "Resumen de activos",
		asset_verview_conversion: "Resumen de activos convertidos",
		account_assets: "Activos de la cuenta",
		financial_records: "Registros financieros",
		tab_trade: "Cuenta de monedas",
		tab_legal: "Cuenta en moneda fiduciaria",
		tab_lever: "Cuenta del contrato",
		tab_second: "Cuenta Extreme Contracts",
		tab_financial: "Cuenta financiera",
		tab_transfer: "Transferencia",
		recharge: "Carga de monedas",
		withdraw: "Retirar dinero",
		currency_transfer: "Transferencia de divisas",
		asset_transfer: "Transferencia de activos",
		transfer_record: "Transferencia de registros",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "Registros DeFi",
		reference_annualization: "Referencia Anualizada",
		state: "Situación",
		term: "Plazos",
		day: "Día",
		buy: "Comprar",
		min_amount: "Importe mínimo",
		daily: "Producción diaria",
		waiting_time: "Tiempo de espera",
		time: "Veces",
		everyday: "Todos los días",
		escrow_funds: "Fondos en fideicomiso",
		returnType: "Rentabilidad al vencimiento",
		early_withdrawal: "Retirada anticipada de efectivo",
		estimated_income: "Ingresos previstos",
		available_assets: "Activo disponible"
	},
	home: {
		download: "Descarga de TCOIN",
		ios_download: "Descarga IOS",
		android_download: "Descarga para Android",
		banner_p1: "Encienda rápidamente su cartera -->",
		banner_p2: "Inicie rápidamente su",
		banner_p3: "Cartera cifrada",
		banner_p4: "TCOIN es el lugar más fácil para comprar y vender criptomonedas. ¡Regístrate ahora y empieza!",
		view_more: "Ver más",
		p1: "La principal plataforma de negociación de activos digitales del mundo",
		p2: "Proporcionar servicios seguros y fiables de negociación y gestión de activos digitales a millones de usuarios en más de 130 países de todo el mundo.",
		p3: "Seguro y fiable",
		p4: " Años de experiencia en servicios financieros de activos digitales",
		p5: "Arquitectura distribuida profesional y sistema de ataque anti-DDOS",
		p6: "Trazado ecológico global",
		p7: "Centros de servicios comerciales localizados en más de 50 países",
		p8: "Creación de un ecosistema blockchain con múltiples formas de negocio en su conjunto",
		p9: "El usuario es lo primero",
		p10: "Establecimiento de un mecanismo de pago por adelantado",
		p11: "Creación de un fondo de protección de los inversores",
		p12: "Comience hoy mismo su viaje por la moneda digital.",
		p13: "Proporcionarle la forma más cómoda de operar con divisas digitales",
		p14: "Terminal multiplataforma para operar en cualquier momento y lugar",
		p15: "Cubriendo múltiples plataformas de iOS, Android y Windows, soportando funciones de servicio completo.",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "Registros DeFi",
		label_issue_price: "Precio de emisión",
		label_number: "Suscripción",
		label_money: "Pagos totales",
		label_time: "Periodo de suscripción",
	},
	legal: {
		banner_p1: "C2C: Compra y venta rápida de moneda digital",
		banner_p2: "C2C es una plataforma de comercio entre iguales en la que los usuarios pueden interactuar directamente con los comerciantes.",
		banner_p3: "Compre y venda Bitcoin y otras monedas digitales al precio y forma de pago que desee.。",
		table_legal: "Parqué",
		table_order: "Órdenes de compra y venta de monedas",
		buy: "Comprar",
		sell: "Oferta de venta",
		steps_title: "Tres pasos para completar una transacción P2P y comprar monedas ¡sin comisiones!",
		steps_tit1: "Seleccione su comerciante",
		steps_tit2: "Recibir/pagar a los vendedores",
		steps_tit3: "Emisión/recepción de criptomoneda",
		steps_p1: "Elija un anuncio que se ajuste a su precio preferido, así como la forma de pago.。",
		steps_p2: "Introduzca la cantidad de compra/venta y el importe de la transacción para completar la orden.",
		steps_p3: "Recibir/pagar al vendedor mediante el método de pago sugerido.",
		steps_p4: "Después de completar la transacción fiat, en TCOIN P2P haga clic en el botón",
		steps_p5: "[Transferido, notificar al vendedor]. TCOIN no cobrará ninguna comisión.",
		steps_p6: "Las divisas se liberarán cuando el vendedor reciba el pago. Ir a la cuenta fiat para ver",
		steps_p7: "Recibes la moneda. Tras confirmar la recepción del dinero, podrás verlo en el P2P de TCOIN.",
		steps_p8: "Sobre la emisión de criptomoneda a los compradores。",
	},
	lever: {
		market_trand: "Transacción a precio de mercado",
		limit_trand: "limitar el comercio",
		tip: "1 lote equivale a 1BTC",
		handicap: "Encrucijada",
		whole_transaction: "Transacción en todo el sitio",
		tab1: "Posición actual",
		tab2: "Posición completa",
		tab3: "Todos los pedidos pendientes",
		tab4: "Cerrar una posición completa",
		tab5: "Retirada de todos los pedidos",
		tab6: "Cerrar posición con un clic",
		close_position: "Determinación del cierre de posición",
		position1: "Cerrar una posición completa",
		position2: "Sólo pedidos múltiples",
		position3: "Basándose únicamente en órdenes vacías (modismo); sin ninguna prueba",
		positionState1: "Puesto pendiente de apertura",
		positionState2: "A la espera del cierre de una posición",
		positionState3: "Hecho",
	},
	login: {
		tip1: "Bienvenido a TCOIN",
		tip2: "Opere en cualquier momento y lugar",
		tip3: "Bienvenido a TCOIN",
		tip4: "Bienvenido a TCOIN",
	},
	second: {
		transfer_model: "Patrón de negociación",
		open_number: "Número de puestos abiertos",
		open_time: "Hora de apertura",
		second_account: "Segunda cuenta contractual",
		profit_rate: "Rentabilidad",
		buy_long: "Comprar (precios)",
		buy_short: "Comprar y vender a la baja",
		transfering: "En proceso de cerrar un trato",
		closed_position: "Posición cerrada",
	},
	trade: {
		transfer_amount: "Facturación",
		buy: "Buy-in",
		sell: "Alcance (un precio en una subasta)",
		now_entrust: "Comisiones actuales",
		all_entrust: "Comisión completa",
		history_entrust: "Comisiones históricas",
	},
	personal: {
			tit: "Información básica",
			tit1: "Personas",
			tit2: "Nivel de seguridad",
			state1: "Vigoroso",
			nav1: "Verificación de correo electrónico",
			nav2: "Mi código de invitación",
			nav3: "Contraseña de acceso",
			nav4: "Contraseña",
			nav5: "Ajustes de recogida",
			nav6: "Autentifique",
			nav7: "Comentarios de los usuarios",
			modify: "Modificaciones",
			goSetting: "Seguir adelante y configurar",
			login_psd: "Cambiar contraseña de inicio de sesión",
			trans_psd: "Cambiar contraseña",
			collection: "Ajustes de recogida",
			authentication: "Autentifique",
			feedback: "Comentarios de los usuarios"
	},
	form: {
		label_oldPsd: "Contraseña antigua",
		place_oldPsd: "Por favor, introduzca la contraseña antigua",
		label_newPsd: "Nueva contraseña",
		place_newPsd: "Introduzca una nueva contraseña",
		label_crmPsd: "Confirmar contraseña",
		place_crmPsd: "Vuelva a introducir la nueva contraseña",
		label_bankType: "Método de pago",
		place_bankType: "Seleccione un método de pago",
		label_bank: "Tarjeta de cajero automático",
		label_realName:"Nombre y apellidos reales",
		palce_realName:"Introduzca su nombre real",
		label_bankName:"Apertura de cuenta bancaria",
		palce_bankName:"Introduzca la cuenta bancaria",
		label_bankBranch:"Apertura de cuenta Sucursal",
		palce_bankBranch:"Indique la sucursal en la que está abierta la cuenta",
		label_bankNumber:"Número de tarjeta ATM",
		palce_bankNumber:"Introduzca el número de su tarjeta bancaria",
		label_cardNumber: "Número de certificado",
		place_cardNumber: "Introduzca su número de identificación",
		card_img1: "Haga clic para cargar la página de inicio de ID",
		card_img2: "Haga clic para cargar la página de información del documento",
		card_img_tip:"La información que envíe será encriptada y almacenada de forma aislada, y la información enviada será revisada automáticamente por el sistema, así que no dude en rellenarla.",
		label_feedbackType: "Tipo de problema",
		place_feedbackType: "Seleccione el tipo de pregunta",
		feedbackType0: "Relacionado con la retirada",
		feedbackType1: "Relacionado con Blockchain",
		label_feedbackTitle: "Leyenda",
		place_feedbackTitle: "Introduzca un título",
		label_feedbackCont: "Descripciones",
		place_feedbackCont: "Introduzca una descripción",
		place_currency: "Seleccione la moneda",
		place_account: "Seleccione una cuenta",
		label_acrrency: "Tipo de moneda",
		label_number: "Cantidades",
		label_record: "Récord (en deportes, etc.)",
		label_time: "Veces",
		label_available: "Usabilidad",
		label_freeze: "Congelación (préstamo, salario, precio, etc.)",
		label_total: "Ascienden a",
		label_operate: "Plataforma",
		label_transfer_currency: "Moneda de la transferencia",
		label_transfer_account: "Cuenta de transferencia",
		label_account_balance: "Saldo de la cuenta",
		label_transfer_number: "Número de transferencias",
		place_transfer_number: "Introduzca el número de transferencias",
		label_buy_price: "Precio de oferta",
		label_buy_number: "Volumen de compras",
		place_buy_market: "Comprar al mejor precio del mercado",
		label_sell_price: "Tasa de venta",
		label_sell_number: "Volumen de ventas",
		place_sell_market: "Vender al mejor precio del mercado",
		product_name: "Cloruro de dietilamonio",
		buy_total: "Número total de compras",
		earnings: "Ganancia acumulada",
		product_cycle: "Ciclo del producto",
		state: "Situación",
		time: "Veces",
		place_phone_email: "Buzón de telefonía móvil",
		login: "Iniciar sesión",
		register: "Inscripción",
		label_price: "Precios",
		place_price: "Introduzca un precio",
		label_multiple: "Factor",
		place_multiple: "Seleccione el multiplicador",
		label_hand: "Número ordinal",
		place_hand: "Introduzca el número de lotes",
		label_volume: "Volumen 24H",
		daily_fluctuations: "Subida o bajada del precio al día",
		label_quotes: "últimos tres días",
		label_toTrade: "Adelante y haz un trato",
		available_amount: "Cantidad disponible",
		label_transfer_psd: "Contraseña",
		palce_transfer_psd: "Introduzca la contraseña de la transacción",
		buy_long: "Soborno",
		sell_short: "Vender en corto",
		elail_login: "Inicio de sesión por correo electrónico",
		phone_login: "Inicio de sesión móvil",
		label_email: "Bandeja de entrada",
		place_email: "Introduzca su dirección de correo electrónico",
		label_phone: "Número de teléfono móvil",
		place_phone: "Introduzca su número de teléfono móvil",
		label_password: "Criptográfico",
		place_password: "Introduzca su contraseña",
		remember_psd: "Recordar una contraseña",
		no_account: "Aún no tengo cuenta.？",
		forget_psd: "¿Ha olvidado su contraseña?？",
		label_verify: "CAPTCHA, un tipo de prueba de desafío-respuesta (informática)",
		place_Verify: "Introduzca el código de verificación",
		send: "Enviando",
		label_invite: "Código de invitación",
		place_invite: "Introduzca el código de invitación",
		have_account: "Tener una cuenta？",
		go_login: "Ir al inicio de sesión",
		label_linkName: "Nombre de la cadena",
		label_recharge_address: "Dirección de retirada de monedas",
		place_recharge_address: "Introduzca la dirección de retirada",
		label_recharge_number: "Número de monedas",
		place_recharge_number: "Introduzca el número de monedas",
		label_wallet_address: "Dirección de la cartera",
		place_wallet_address: "Introduzca la dirección de su monedero",
		label_withdraw_number: "Número de monedas retiradas",
		place_withdraw_number: "Introduzca el número de monedas que desea retirar",
		place_min_withdraw: "Número mínimo de monedas a retirar",
		label_remark: "Nota",
		place_remark: "Introduzca una nota",
		label_transPsd: "Contraseña",
		place_transPsd: "Introduzca la contraseña de la transacción",
		message1: "Introduzca el importe",
		message2: "Saldo de cuenta insuficiente",
		label_amount: "Suma de dinero",
		place_amount: "Introduzca el importe",
	},
	table: {
		label_time: "Veces",
		label_title: "Contraparte de la transacción",
		label_direction: "Orientaciones",
		label_price: "Precios",
		label_number: "Cantidades",
		label_volume: "Facturación",
		label_operate: "Plataforma",
		label_acrrency: "Tipo de moneda",
		label_last_price: "último precio",
		label_ncrease: "Subida y bajada de las acciones en un día de negociación",
		label_height_price: "Precio más alto",
		label_low_price: "Precio más bajo",
		label_quantity: "Volumen 24H",
		label_buyPrice: "Precio de compra",
		label_finalPrice: "Precio alcanzado en una subasta",
		label_expectedProfit: "Pérdidas y ganancias previstas",
		label_countDown: "Cuenta atrás",
		sell: "Presumir o alardear",
		buy: "Comprar",
		label_type: "Tipología",
		label_open_price: "Precio de apertura",
		label_now_price: "Precio actual",
		label_bail: "Margen (en la negociación de derivados)",
		label_stop_profit: "Precio de recogida de beneficios",
		label_stop_loss: "Precio de mercado",
		label_open_time: "Hora de apertura",
		label_fee: "Gastos de tramitación",
		label_overnight_fee: "Carga nocturna",
		label_profit_loss: "Creciente y menguante",
		label_total_amount: "Importe total",
		label_order_time: "Longitud del pedido",
		label_order_income: "Ingresos por pedidos",
		label_state: "Situación",
		label_endTime: "Hora final",
		state0: "En espera de aprobación",
		state1: "Hecho",
	},
	params: {
		recordType1: "Gastos",
		recordType2: "Recarga (dinero en una tarjeta)",
		ieoState0: "Completo",
		ieoState1: "Preventa",
		ieoState2: "Suscripción en curso",
		ieoState3: "Cerrado",
		depositState0: "Caliente en marcha.",
		depositState1: "Esperamos",
		lockStateType0: "Corriendo",
		lockStateType1: "Cerrado",
		lockStateType2: "Cancelado",
	},
	city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahréin",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "abreviatura de Bosnia-Herzegovina",
	    brazil: "Brasileño",
	    brunei: "Brunei Darussalam, sultanato independiente en el noroeste de Borneo",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadiense",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Columbia (Distrito de, o Universidad, etc.)",
		costa_Rica: "Costa Rica",
		croatia: "República de Croacia (1991-)",
		cyprus: "Chipre",
		czech_Republic: "República Checa (desde 1993)",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Suomi",
		france: "Francés",
		georgia: "Georgia (país)",
		germany: "Alemán",
		ghana: "Ghana, África Occidental",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlandés",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Iraqi",
		japan: "Japonés",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur (República de Corea)",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"La república lituana, antigua república soviética báltica",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonio",
		malaysia:"Malasia",
		malta:"Maltés",
		mexico:"México",
		moldova:"República de Moldavia, antigua república soviética fronteriza con Rumanía",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar (o Birmania)",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepalí",
		nigeria:"Nigeria, África Occidental",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Peruano",
		philippines:"Filipinas",
		poland:"Polaco",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Doha",
		romania:"Rumanía",
		russia:"Georgia",
		republic_of_Trinidad_and_Tobago:"Trinidad y Tobago",
		rwanda:"Ruanda",
		saudi_Arabia:"Arabia Saudí",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"Español",
		sri_Lanka:"(antiguamente) Ceilán",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"China-Taiwán",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Estambul",
		turkmenistan:"Turkmenistán",
		ukraine:"Bielorrusia",
		united_Arab_Emirates:"EAU",
		united_Kingdom:"Reino Unido de Gran Bretaña e Irlanda del Norte",
		united_States:"Estados Unidos de América",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán, ",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benín",
		bhutan:"Bután",
		bolivia:"Bolivia",
		botswana:"Botsuana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso, África Occidental",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (Brazzaville)",
		democratic_Republic_of_the_Congo:"Congo (República Democrática del)",
		djibouti:"Yibuti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiyi ",
		gabon:"Gabonesa",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Mann",
		cote_d_Ivoire:"Costa de Marfil en África Occidental",
		jamaica:"Jamaica",
		jordan:"jordan",
		lebanon:"Hostilidades",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí, África Occidental",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Dinastía coreana Joseon o Chosun 1392-1910",
		reunion:"Reunión (isla del océano Índico, departamento francés de ultramar)",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Mogadiscio",
		sudan:"sultán",
		suriname:"Surinam",
		eswatini:"Suazilandia",
		syria:"Siria",
		togo:"Togo, África Occidental",
		tonga:"Tonga,",
		tunisia:"Túnez, capital de Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de Estados Unidos (USVI)",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemení",
		yugoslavia:"Yugoslavia, 1943-1992",
		zambia:"Seychelles",
		zimbabwe:"Zimbabue",
		china:"China",
    }
};
